const ISAUTH = 'ISAUTH';
const ISNOTAUTH = 'ISNOTAUTH';
const EXPORTALLSPECIALITIES = 'EXPORTALLSPECIALITIES';
const UPDATE_SPECIALITY = 'UPDATE_SPECIALITY';
const DELETE_SPECIALITY = 'DELETE_SPECIALITY';
const ADD_SPECIALITY = 'ADD_SPECIALITY';
const EXPORTALLGROUPS = 'EXPORTALLGROUPS';
const DELETE_GROUP = 'DELETE_GROUP';
const UPDATE_GROUP = 'UPDATE_GROUP';
const ADD_GROUP = 'ADD_GROUP';
const EXPORTALLSCHOOLYEARS = 'EXPORTALLSCHOOLYEARS';
const EXPORTSTUDENTDATA = 'EXPORTSTUDENTDATA';
const STUDENTISAUTH = 'STUDENTISAUTH';
const STUDENTISNOTAUTH = 'STUDENTISNOTAUTH';

export default { 
    ISAUTH, ISNOTAUTH, EXPORTALLSPECIALITIES, UPDATE_SPECIALITY,
    DELETE_SPECIALITY, ADD_SPECIALITY, EXPORTALLGROUPS, DELETE_GROUP,
    UPDATE_GROUP, ADD_GROUP, EXPORTALLSCHOOLYEARS, EXPORTSTUDENTDATA,
    STUDENTISAUTH, STUDENTISNOTAUTH
};